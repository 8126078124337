// import { PushNotifications } from '@capacitor/push-notifications';
import wrapWithProvider from './wrap-with-provider'

export const onInitialClientRender = () => {
  document.getElementById('___loader').style.display = 'none'
}

export const wrapRootElement = wrapWithProvider // eslint-disable-line

// Temporary workaround because Gatsby is fucking shit
export const onClientEntry = () => {
  // Ensure the script is only added on the client side
  if (typeof window !== 'undefined') {
    // Load Appy Chat
    const host = `${window.location.protocol}//${window.location.hostname}:${window.location.port}` // Get the current host and protocol

    const script = document.createElement('script')
    script.src = `${host}/AppyChat/index.js`
    script.async = true
    script.onload = () => {
      console.log('Appy Chat loaded')
    }
    document.body.appendChild(script)
    // Load Appy Chat end

    // Init Push Notifications
    // if (PushNotifications) {

    // }
    // Init Push Notifications end
  }
}
