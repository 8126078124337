/* eslint no-underscore-dangle: 0 */
import { action, autorun, computed, observable, runInAction, makeObservable } from 'mobx'
import moment from 'moment-timezone'
import MiniSearch from 'minisearch'
import viewStore from 'stores/viewStore'
import profileStore from 'stores/profileStore'
import * as API from 'stores/api'
import { formatPhone } from 'helpers/phoneFunctions'

class UserStore {
  users = []

  filteredUsers = []

  error = false

  loadingUsers = false

  userId = null

  user = {}

  loadingUser = false

  updatingUser = false

  loadingWalks = false

  uploadingPhoto = false

  miniSearch = null

  searchParam = ''

  constructor() {
    makeObservable(this, {
      users: observable,
      filteredUsers: observable,
      error: observable,
      loadingUsers: observable,
      userId: observable,
      user: observable,
      loadingUser: observable,
      updatingUser: observable,
      loadingWalks: observable,
      uploadingPhoto: observable,
      searchParam: observable,
      hasSelectedUser: computed,
      userPhoto: computed,
      getUsers: action,
      getUser: action,
      createUser: action,
      updateUser: action,
      canClientBeDeleted: action,
      deleteUser: action,
      getUserDogs: action,
      getWalks: action,
      cancelWalk: action,
      uploadClientPhoto: action,
      setSearchParam: action,
      initSearch: action,
      findUser: action,
    })

    this.initSearch()

    autorun(() => {
      this.findUser(this.searchParam)
    })

    autorun(() => {
      // isClient will always be true until the user data is loaded, so try again after
      if (!profileStore.isClient) this.getUsers()
    })
  }

  get hasSelectedUser() {
    return this.userId
  }

  get userPhoto() {
    return this.user.photo
  }

  getUsers = async () => {
    this.loadingUsers = true
    this.error = null
    try {
      const { users } = await API.getUsers()
      runInAction(() => {
        if (users) {
          this.users = users
          this.filteredUsers = users
          this.initSearch()
          this.indexDocuments(users)
        }
        this.loadingUsers = false
      })
    } catch (e) {
      console.log('getUsers error', e)
      runInAction(() => {
        this.error = true
        this.loadingUsers = false
      })
    }
  }

  setCurrentUser = action((id) => {
    this.userId = id
  })

  getUser = async () => {
    const { userId } = this
    if (!userId) {
      return {}
    }

    this.error = false
    this.loadingUser = true
    try {
      const data = await API.getUser(userId)

      if (data.error) {
        runInAction(() => {
          this.loadingUser = false
          this.user = {}
          this.error = 'USER_NOT_FOUND'
        })
        viewStore.notify('danger', 'Client not found')
        return {}
      }
      runInAction(() => {
        this.loadingUser = false
        this.user = data.user
      })

      return data.user
    } catch (e) {
      console.log('getUser error', e)
      // viewStore.notify('warning', 'Loading client failed')
      runInAction(() => {
        this.loadingUser = false
        this.error = true
        this.user = {}
      })
      return {}
    }
  }

  async createUser(data) {
    this.updatingUser = true
    const userData = data
    userData.phone = formatPhone(userData.phone)

    try {
      const newUser = await API.createUser(userData)
      runInAction(() => {
        this.updatingUser = false
        this.userId = newUser._id
        this.user = newUser.user
      })
      return newUser
    } catch (e) {
      console.log('createUser failed', e)
      runInAction(() => {
        this.updatingUser = false
        this.user = {}
      })
      return {
        error: e,
        message: e.message || 'Creating client failed',
      }
    }
  }

  async updateUser(data) {
    const { userId } = this
    const userData = data
    this.updatingUser = true
    if (userData.phone) {
      userData.phone = formatPhone(userData.phone)
    }

    try {
      const response = await API.updateUser(userId, userData)
      runInAction(() => {
        this.updatingUser = false
        if (this.user._id === response.user._id) {
          this.user = response.user
        }
      })
      return response
    } catch (e) {
      runInAction(() => {
        this.updatingUser = false
      })
      return {
        error: e,
        message: e.message || 'Update failed',
      }
    }
  }

  canClientBeDeleted = async (id) => {
    try {
      const result = await API.canClientBeDeleted(id)
      console.log('canClientBeDeleted', result)
      return result
    } catch (e) {
      console.log('canClientBeDeleted error', e)
      return { success: false }
    }
  }

  deleteUser = async (id) => {
    try {
      const result = await API.deleteUser(id)
      console.log('deleteUser', result)
      if (result.success) {
        viewStore.notify('success', 'Client removed.')
      } else {
        viewStore.notify('warning', 'Client not removed.')
      }
      return result
    } catch (e) {
      console.log('deleteUser error', e)
      // viewStore.notify('warning', `Client not removed.`)
      return { success: false, message: e.message || 'Client removing failed' }
    }
  }

  /**
   * DOGS
   */

  getUserDogs = async () => {
    const { userId } = this
    if (!userId) {
      console.log('No client id')
      return {}
    }

    this.error = false
    this.loadingUser = true

    try {
      const { dogs } = await API.getUserDogs(userId)
      const dogsObject = {}
      dogs.forEach((d) => {
        dogsObject[d._id] = d
      })
      runInAction(() => {
        this.loadingUser = false
      })
      return dogsObject
    } catch (e) {
      console.log('getUserDogs error', e)
      // viewStore.notify('warning', 'Loading dogs failed')
      runInAction(() => {
        this.loadingUser = false
        this.error = true
      })
      return {}
    }
  }

  /**
   * WALKS
   */

  getWalks = async () => {
    const { userId } = this
    this.error = null
    try {
      this.loadingWalks = true
      const params = {
        fromTime: moment().subtract(14, 'days').startOf('day').valueOf(),
      }
      const { walks } = await API.getUserWalks(userId, params)
      console.log('getWalks', walks)
      runInAction(() => {
        this.loadingWalks = false
      })
      return walks || []
    } catch (e) {
      console.log('getWalks error', e)
      runInAction(() => {
        this.error = true
        this.loadingWalks = false
      })
      return []
    }
  }

  cancelWalk = async (id) => {
    try {
      const result = await API.cancelWalk(id)
      console.log('cancelWalk', result)
      if (result.walk.status === 'CANCELLED' || result.walk.status === 'CANCELLED_REFUNDED') {
        viewStore.notify('success', 'Booking cancelled successfuly.')
      } else {
        viewStore.notify('warning', 'Booking not cancelled. Contact us.')
      }
      this.getWalks()
    } catch (e) {
      console.log('cancelWalk error', e)
      // viewStore.notify('warning', `Booking not cancelled. Contact us.`)
    }
  }

  uploadClientPhoto = async (photo) => {
    const { userId } = this
    this.uploadingPhoto = true

    try {
      const data = await API.uploadUserPhoto(userId, photo)
      runInAction(() => {
        this.uploadingPhoto = false
        console.log('UPLOADED PHOTO', data)
        if (data.user) {
          this.user = data.user
        }
      })

      viewStore.notify('success', 'Client photo uploaded.')

      return data
    } catch (e) {
      console.log('uploadClientPhoto', e.message || e)
      runInAction(() => {
        this.uploadingPhoto = false
      })
      throw e
    }
  }

  setSearchParam = (value) => {
    this.searchParam = value
  }

  initSearch() {
    this.miniSearch = new MiniSearch({
      fields: ['name', 'phone', 'email', 'postcode', 'address', 'petsNames'],
      tokenize(value, fieldName) {
        if (fieldName === 'phone') {
          // Get rid of spaces
          const phone = value.replaceAll(' ', '')
          // Allow users to search by prefixing phone number with "0" also
          return [phone, phone.replace('+44', '0')]
        }

        // Split by spaces into array of words
        return value.split(' ')
      },
      searchOptions: {
        boost: { name: 2, petsNames: 2 },
        fuzzy: 0.3,
      },
    })
  }

  indexDocuments(users) {
    this.miniSearch.removeAll()
    this.miniSearch.addAll(
      users.map((u) => ({
        id: u._id,
        petsNames: u.pets ? u.pets.map((p) => p.name).join(' ') : '',
        ...u,
      })),
    )
  }

  findUser = async (searchPhrase) => {
    let phrase = searchPhrase
    if (!phrase.length) {
      runInAction(() => {
        this.filteredUsers = this.users
      })
      return
    }

    // If the phrase starts with '+4' or '0', remove spaces
    if (phrase.startsWith('+4') || phrase.startsWith('0')) {
      phrase = phrase.replaceAll(' ', '')
    }

    const result = this.miniSearch.search(phrase, { prefix: true })
    const filteredUsers = result.map((u) => this.users.find((res) => res._id === u.id))
    runInAction(() => {
      this.filteredUsers = filteredUsers
    })
  }
}

export default new UserStore()
