import { configure } from 'mobx'

// STORES
import bookingStore from './bookingStore'
import chatStore from './chatStore'
import companyStore from './companyStore'
import dogStore from './dogStore'
import invoiceStore from './invoiceStore'
import profileStore from './profileStore'
import viewStore from './viewStore'
import userStore from './userStore'
import staffStore from './staffStore'
import serviceStore from './serviceStore'
import tourStore from './tourStore'

const stores = {
  bookingStore,
  chatStore,
  companyStore,
  dogStore,
  invoiceStore,
  profileStore,
  viewStore,
  userStore,
  staffStore,
  serviceStore,
  tourStore,
}

configure({
  enforceActions: 'always',
})

export default stores
